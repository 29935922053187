import { httpMethods } from '@/api';
import { sendApiRequest } from '@/api/sendApiRequest';
import {
  TDocumentResponse,
  TDocumentsResponse,
  TExportToFileResponse,
  TFileUpload,
  TFileUploadInfo,
  TImportInfoResponse,
  TPagination,
} from '@/types';
import {
  TPartnershipGroupResponse,
  TIncomingInvitationsResponse,
  TPartnershipDocumentRequest,
  TPartnershipsResponse,
  TContractResponse,
  TContractsResponse,
  TPartnershipsStatisticsResponse,
  TPartnershipStatisticsResponse,
  TCreateContractRequest,
  TProlongContractRequest,
  TPartnerCandidatesResponse,
  TPartnershipResponse,
  TContractTypesResponse,
  TContractCustomColumnsResponse,
  TContractCustomAttributesSchemaResponse,
  TContractCustomAttributesFormSchemaResponse,
  TPartnershipPartnerContactsResponse,
  TExportContractsToExcelPayload,
  TChangePartnershipResourceStatusPayload,
  TUpdatePartnershipResourceStatusResponse,
  TPartnershipResourcesVehiclesResponse,
  TPartnershipResourcesDriversResponse,
  TPartnershipResourcesVehicleInfoResponse,
  TPartnershipResourcesDriverInfoResponse,
  TFetchPartnershipResourcesPayload,
  TResponsibleEmployeesResponse,
  TPartnerTenantInfo,
} from '@/contexts/partnershipsContext/domain/types';
import { VEHICLE_UNIT_BY_TYPE } from '@/contexts/resourcesControlContext/domain/constants';
import tt from '@/i18n/utils/translateText';
import { EContractsTableFilter } from '@/contexts/partnershipsContext/components/ContractsTable/domain/constants';
import { EPartnershipsInvitationType } from '@/contexts/partnershipsContext/views/PartnershipsListView/domain/constants';
import { TPartnershipsRequest } from '@/contexts/partnershipsContext/views/PartnershipsListView/components/PartnershipsPartners/types';
import { EAcceptanceMethod, EPartnershipType } from '@/contexts/partnershipsContext/domain/constants';
import { TFuncApplySetServiceParams } from '@/components/Filter/types';

export function inviteToPartnership(partnerId: string, initiatorRole: EPartnershipType) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/outgoing_invitations',
    payload: {
      partnerId,
      initiatorRole,
    },
  });
}

export function addExternalNumberForOutgoingInvitation(
  invitationId: string,
  externalNumber: string,
) {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: `:tenant_id/outgoing_invitations/${invitationId}/external_number`,
    payload: { externalNumber },
    requestOptions: {
      showError: true,
      message: tt('partnerships.shared.errors.addExternalNumber'),
    },
  });
}

export function acceptInvitation(invitationId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/incoming_invitations/${invitationId}/acceptance`,
    requestOptions: {
      showError: true,
      message: tt('partnerships.shared.errors.acceptInvitation'),
    },
  });
}

export function rejectInvitation(invitationId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/incoming_invitations/${invitationId}/rejection`,
    requestOptions: {
      showError: true,
      message: tt('partnerships.shared.errors.rejectInvitation'),
    },
  });
}

export function revokeInvitation(invitationId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/outgoing_invitations/${invitationId}/revocation`,
    requestOptions: {
      showError: true,
      message: tt('partnerships.shared.errors.revokeInvitation'),
    },
  });
}

export function fetchIncomingInvitations({ pageItems = 10, currentPage }: TPagination, searchQuery: string) {
  return sendApiRequest<TIncomingInvitationsResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/incoming_invitations',
    params: {
      items: pageItems,
      page: currentPage,
      searchQuery,
    },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function fetchOutgoingInvitations({ pageItems = 10, currentPage }: TPagination, searchQuery: string) {
  return sendApiRequest<TIncomingInvitationsResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/outgoing_invitations',
    params: {
      items: pageItems,
      page: currentPage,
      searchQuery,
    },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export const fetchPartnerCandidates = (
  query: string,
  role: string,
) => sendApiRequest<TPartnerCandidatesResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/autocomplete/partner_candidates',
  params: {
    query,
    role,
  },
});

export const fetchPartnerships = (
  {
    pagination, role, groupIds, searchQuery,
  }: TPartnershipsRequest) => sendApiRequest<TPartnershipsResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/partnerships',
  params: {
    items: pagination.pageItems,
    page: pagination.currentPage,
    role,
    groupIds,
    searchQuery,
  },
  requestOptions: {
    showError: true,
    message: tt('partnerships.shared.errors.fetchPartnerships'),
  },
});

export const fetchPartnershipInfo = (partnershipId: string) => sendApiRequest<TPartnershipResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/${partnershipId}`,
  requestOptions: {
    showError: true,
    message: tt('partnerships.shared.errors.fetchPartnershipInfo'),
  },
});

export const fetchPartnershipsStatistics = () => sendApiRequest<TPartnershipsStatisticsResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/partnerships_statistics',
});

export const fetchPartnershipStatistics = ({ partnershipId }) => sendApiRequest<TPartnershipStatisticsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/statistics`,
});

export const fetchVehiclesForPartnership = ({ type, pagination, partnershipId }: {
  type: any, pagination: TPagination, partnershipId: string,
}) => {
  const vehicleUnitType = VEHICLE_UNIT_BY_TYPE[type];
  if (!vehicleUnitType) return Promise.reject();

  return sendApiRequest({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_vehicles`,
    params: {
      items: pagination.pageItems,
      page: pagination.currentPage,
      vehicleUnitType,
    },
  });
};

export const rejectDriver = (partnershipId: string, driverId: string) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/reject_driver`,
  params: { driverId },
});

export const fetchPartnershipDrivers = (
  partnershipId: string,
  {
    pageItems,
    currentPage,
  }: TPagination,
) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_drivers`,
  params: {
    items: pageItems,
    page: currentPage,
  },
});

export const fetchDataForInvitationList = (
  { type, pagination, searchQuery }:
  { type: EPartnershipsInvitationType, pagination: TPagination, searchQuery: string },
) => {
  switch (type) {
    case EPartnershipsInvitationType.incoming:
      return fetchIncomingInvitations(pagination, searchQuery);
    case EPartnershipsInvitationType.outgoing:
      return fetchOutgoingInvitations(pagination, searchQuery);
    default:
      return Promise.reject();
  }
};

export const requestInvitationAction = ({ type, invitationId }: { type: string, invitationId: string }) => {
  switch (type) {
    case 'accept':
      return acceptInvitation(invitationId);
    case 'reject':
      return rejectInvitation(invitationId);
    case 'revocate':
      return revokeInvitation(invitationId);
    default:
      return Promise.reject();
  }
};

export function fetchPartnerGroups() {
  return sendApiRequest<TPartnershipGroupResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/partnerships/groups',
  });
}

export function createPartnerGroup(name: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/partnerships/groups',
    params: { name },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function editPartnerGroup(groupId: string, name: string) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: `/:tenant_id/partnerships/groups/${groupId}`,
    params: { name },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function deletePartnerGroup(groupId: string) {
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: `/:tenant_id/partnerships/groups/${groupId}`,
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function addPartnerToGroup(groupId: string, partnerId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/partnerships/${partnerId}/partnership_group`,
    params: { groupId },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
      message: tt('partnerships.shared.errors.addPartnerToGroup'),
    },
  });
}

export function removePartnerFromGroup(groupId: string, partnerId: string) {
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: `/:tenant_id/partnerships/${partnerId}/partnership_group`,
    params: { groupId },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
      message: tt('partnerships.shared.errors.removePartnerFromGroup'),
    },
  });
}

/** Обновление заказчиком статуса ресурса (ТС) в партнерстве */
export function updatePartnershipVehicleClientStatus({
  resourceId,
  partnershipId,
  status,
  additionalParams,
}: TChangePartnershipResourceStatusPayload) {
  return sendApiRequest<TUpdatePartnershipResourceStatusResponse>({
    method: httpMethods.PATCH,
    payload: {
      status,
      additionalParams,
    },
    endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_vehicles/${resourceId}/client_status`,
  });
}

/** Обновление заказчиком статуса ресурса (водитель) в партнерстве */
export function updatePartnershipDriverClientStatus({
  resourceId,
  partnershipId,
  status,
  additionalParams,
}: TChangePartnershipResourceStatusPayload) {
  return sendApiRequest<TUpdatePartnershipResourceStatusResponse>({
    method: httpMethods.PATCH,
    payload: {
      status,
      additionalParams,
    },
    endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_drivers/${resourceId}/client_status`,
  });
}

/** Обновление исполнителем статуса своего ресурса (водитель) в партнерстве */
export function updatePartnershipVehicleServicerStatus({
  resourceId,
  partnershipId,
  status,
  additionalParams,
}: TChangePartnershipResourceStatusPayload) {
  return sendApiRequest<TUpdatePartnershipResourceStatusResponse>({
    method: httpMethods.PATCH,
    payload: {
      status,
      additionalParams,
    },
    endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_vehicles/${resourceId}/servicer_status`,
  });
}

/** Обновление исполнителем статуса своего ресурса (ТС) в партнерстве */
export function updatePartnershipDriverServicerStatus({
  resourceId,
  partnershipId,
  status,
  additionalParams,
}: TChangePartnershipResourceStatusPayload) {
  return sendApiRequest<TUpdatePartnershipResourceStatusResponse>({
    method: httpMethods.PATCH,
    payload: {
      status,
      additionalParams,
    },
    endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_drivers/${resourceId}/servicer_status`,
  });
}

export function pausePartnership(partnershipId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/pause`,
  });
}

export function unpausePartnership(partnershipId: string) {
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/pause`,
  });
}

export function cancelPartnership(partnershipId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/cancellation`,
  });
}

export const getPartnershipsUploadingUrl = (
  {
    partnerId,
    filename,
    type,
  }: {
    partnerId: string,
    filename: string,
    type: any,
  },
) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/${partnerId}/upload`,
  params: {
    filename,
    type,
  },
});

export const attachFileToPartnership = ({ file, partnerId }: { partnerId: string, file: any }) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/partnerships/${partnerId}/attachments`,
  payload: { file },
});

export const fetchPartnershipVehicleInformation = (
  {
    partnershipId,
    vehicleId,
  }: {
    vehicleId: string,
    partnershipId: string,
  },
) => sendApiRequest<TPartnershipResourcesVehicleInfoResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_vehicles/${vehicleId}`,
});

export const fetchPartnershipDriverInformation = (
  {
    partnershipId,
    driverId,
  }: {
    driverId: string,
    partnershipId: string,
  },
) => sendApiRequest<TPartnershipResourcesDriverInfoResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_drivers/${driverId}`,
});

export const fetchPartnershipResourceVehicle = (partnershipResourceId: string,
) => sendApiRequest<TPartnershipResourcesVehicleInfoResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships_vehicles/${partnershipResourceId}`,
});

export const fetchPartnershipResourceDriver = (partnershipResourceId: string,
) => sendApiRequest<TPartnershipResourcesDriverInfoResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships_drivers/${partnershipResourceId}`,
});

export function fetchPartnershipDocuments(partnershipId: string) {
  return sendApiRequest<TDocumentsResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/documents`,
  });
}

export function uploadPartnershipDocuments(partnershipId: string, payload: TPartnershipDocumentRequest) {
  return sendApiRequest<TDocumentResponse>({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/documents`,
    payload,
    requestOptions: {
      showError: true,
      message: tt('partnerships.shared.errors.uploadPartnershipDocuments'),
    },
  });
}

export function deletePartnershipDocument(partnershipId: string, documentId: string) {
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/documents/${documentId}`,
  });
}

export const fetchPartnerContacts = (
  partnershipId: string,
  {
    pageItems,
    currentPage,
  }: TPagination,
) => sendApiRequest<TPartnershipPartnerContactsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/contacts`,
  params: {
    items: pageItems,
    page: currentPage,
  },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getContracts = (
  {
    pagination: {
      pageItems = 10,
      currentPage,
    },
    status = EContractsTableFilter.all,
    partnershipId,
    searchQuery = '',
  }: {
    pagination: TPagination,
    status: keyof typeof EContractsTableFilter,
    partnershipId?: string,
    searchQuery?: string,
  },
) => {
  const params: {
    items: number,
    page: number,
    status: keyof typeof EContractsTableFilter,
    partnershipId?: string,
    searchQuery?: string,
  } = {
    items: pageItems,
    page: currentPage,
    status,
  };

  if (partnershipId) {
    params.partnershipId = partnershipId;
  }

  if (searchQuery) {
    params.searchQuery = searchQuery;
  }

  return sendApiRequest<TContractsResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/contracts',
    requestOptions: {
      showError: true,
      message: tt('partnerships.shared.errors.fetchContracts'),
    },
    params,
  });
};

export const getContractTypes = () => sendApiRequest<TContractTypesResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/contracts/contract_types',
});

export const getContract = (
  { contractId }: { contractId: string },
) => sendApiRequest<TContractResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/contracts/${contractId}`,
});

export const archiveContract = (
  { contractId }: { contractId: string },
) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/contracts/${contractId}`,
});

export const unarchiveContract = (
  { contractId }: { contractId: string },
) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/contracts/${contractId}/recover`,
});

export function createContract({ partnershipId, ...payload }: TCreateContractRequest) {
  return sendApiRequest<{ id: string }>({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/contracts`,
    payload,
  });
}

export const editContract = (
  { partnershipId, ...payload }: TCreateContractRequest,
  contractId: string,
) => sendApiRequest<{ id: string }>({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/contracts/${contractId}`,
  payload,
});

export function prolongContract(contractId: string, payload: TProlongContractRequest) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/contracts/${contractId}/prolong`,
    payload,
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export const updatePartnershipExternalNumber = (
  partnershipId: string,
  externalNumber: string,
) => sendApiRequest<{ id: string }>({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/external_number`,
  payload: { externalNumber },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const updatePartnershipDefaultMarginPercent = (
  partnershipId: string,
  defaultMarginPercent: number | null,
) => sendApiRequest<{ id: string }>({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/partnerships/${partnershipId}`,
  payload: {
    defaultMarginPercent,
    ...defaultMarginPercent === null ? { reductionAutoPublish: false } : {},
  },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getContractDocuments = (contractId: string) => sendApiRequest<TDocumentsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/contracts/${contractId}/documents`,
});

export const uploadContractDocument = (
  contractId: string,
  fileData: TFileUploadInfo,
) => sendApiRequest<TDocumentResponse>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/contracts/${contractId}/documents`,
  payload: { file: fileData },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const deleteContractDocument = (contractId: string, documentId: string) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/contracts/${contractId}/documents/${documentId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getContractCustomAttributesSchema = () => sendApiRequest<TContractCustomAttributesSchemaResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/contracts/custom_attributes',
});

export const getContractCustomAttributesFormSchema = () => sendApiRequest<TContractCustomAttributesFormSchemaResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/contracts/custom_attributes/form_schema',
});

export const getContractCustomColumns = () => sendApiRequest<TContractCustomColumnsResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/contracts/custom_attributes/columns',
});

export const changeTargetOfferAcceptanceMethod = (
  partnershipId: string,
  targetOfferAcceptanceMethod: EAcceptanceMethod,
) => sendApiRequest<{ id: string }>({
  method: httpMethods.PUT,
  payload: { targetOfferAcceptanceMethod },
  endpoint: `/:tenant_id/partnerships/${partnershipId}/target_offer_acceptance_method`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const changeReductionAutoPublish = (
  partnershipId: string,
  reductionAutoPublish: EAcceptanceMethod,
) => sendApiRequest<{ id: string }>({
  method: httpMethods.PUT,
  payload: { reductionAutoPublish: reductionAutoPublish === EAcceptanceMethod.auto },
  endpoint: `/:tenant_id/partnerships/${partnershipId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getPartnershipsInvitationsTemplate = () => sendApiRequest<Blob>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/outgoing_invitations/template',
  responseType: 'blob',
  headers: { Accept: 'blob' },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getContractsTemplate = () => sendApiRequest<Blob>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/contracts/template',
  responseType: 'blob',
  headers: { Accept: 'blob' },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const importOutgoingInvitations = ({ file }: TFileUpload) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/outgoing_invitations/imports',
  params: { file },
});

export const getImportOutgoingInvitationsInfo = (id: string) => sendApiRequest<TImportInfoResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/outgoing_invitations/imports/${id}`,
});

export const exportPartnersToExcel = (groupIds: string[]) => sendApiRequest<TExportToFileResponse>({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/partnerships/exports',
  payload: { groupIds },
});

export const exportContractsToExcel = (
  payload: TExportContractsToExcelPayload,
) => sendApiRequest<TExportToFileResponse>({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/contracts/exports',
  payload,
});

export const importContracts = ({ file }: TFileUpload) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/contracts/imports',
  params: { file },
});

export const getContractsImportInfo = (id: string) => sendApiRequest<TImportInfoResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/contracts/imports/${id}`,
});

export const fetchPartnershipResourcesVehicles = ({
  searchQuery = '',
  pagination,
  filterId,
}: TFetchPartnershipResourcesPayload) => sendApiRequest<TPartnershipResourcesVehiclesResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/partnerships_vehicles',
  params: {
    searchQuery,
    items: pagination.pageItems,
    page: pagination.currentPage,
    filterId,
  },
});

export const fetchPartnershipResourcesDrivers = ({
  searchQuery = '',
  pagination,
  filterId,
}: TFetchPartnershipResourcesPayload) => sendApiRequest<TPartnershipResourcesDriversResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/partnerships_drivers',
  params: {
    searchQuery,
    items: pagination.pageItems,
    page: pagination.currentPage,
    filterId,
  },
});

export const fetchVehiclesResponsibleEmployees = () => sendApiRequest<TResponsibleEmployeesResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/autocomplete/partnerships_vehicles/client_employees',
});

export const fetchDriversResponsibleEmployees = () => sendApiRequest<TResponsibleEmployeesResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/autocomplete/partnerships_drivers/client_employees',
});

export const saveFilterForPartnershipResourcesVehiclesTable = (
  { filters, operator }: TFuncApplySetServiceParams,
) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/partnerships_vehicles/filters',
  payload: {
    filters,
    operator,
  },
});

export const saveFilterForPartnershipResourcesDriversTable = (
  { filters, operator }: TFuncApplySetServiceParams,
) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/partnerships_drivers/filters',
  payload: {
    filters,
    operator,
  },
});

export const fetchPartnershipPopupInfo = (id: string) => sendApiRequest<TPartnerTenantInfo>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/public_partnership_popup_contacts/${id}`,
});
