<template>
  <DropdownButton
    class="billing-special-offer-dropdown"
    :type="EButtonType.link"
    :size="ESize.small"
    :title="tt('shared.action.more')"
    :dropdownMatchSelectWidth="400"
  >
    <template #buttonIcon>
      <InfoSvg />
    </template>

    <template #dropdown>
      <div class="billing-special-offer-dropdown__content">
        <label class="billing-special-offer-dropdown__content-title">
          {{ tt('billing.specialOfferDropdown.title') }}
        </label>

        <label>{{ tt('billing.specialOfferDropdown.price', { param: { price: specialOffer.price } }) }}</label>

        <label>{{ tt('billing.specialOfferDropdown.requirements') }}</label>

        <label
          v-for="(requirement, index) in specialOffer.requirements"
          :key="index"
          class="billing-special-offer-dropdown__content-requirement"
        >
          {{ requirement }}
        </label>

        <label>{{ tt('billing.specialOfferDropdown.violationOfTerms') }}</label>
      </div>
    </template>
  </DropdownButton>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
} from 'vue';

import InfoSvg from '@/assets/svg/16x16/info.svg';
import tt from '@/i18n/utils/translateText';
import { TSubscriptionSpecialOffer } from '@/types';
import { EButtonType, ESize } from '@/ui/types';
import DropdownButton from '@/components/DropdownButton/index.vue';

export default defineComponent({
  name: 'BillingSpecialOfferDropdown',
  components: {
    InfoSvg,
    DropdownButton,
  },
  props: {
    specialOffer: {
      type: Object as PropType<TSubscriptionSpecialOffer>,
      required: true,
    },
  },
  setup() {
    return {
      EButtonType,
      ESize,

      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
