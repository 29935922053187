import router from '@/router';
import { useSiderMenuItems } from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/application/useSiderMenuItems';
import { ECommonSchemaItemType } from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/domains/constants';
import store from '@/store';
import { EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { useFoldersStore } from '@/stores/folders';
import { TSubscription } from '@/types';
import { TCommonSchemaItem } from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/domains/types';

import isTenantVerified from './isTenantVerified';

const redirectToInitialRoute = (route?: string | null) => {
  const foldersStore = useFoldersStore();
  const { siderMenuItems } = useSiderMenuItems(foldersStore);

  if (route) {
    return router.push(route);
  }

  const hasActiveSubscription: boolean | undefined = store.state.tenants.currentTenantInfo?.subscriptions?.some(
    (subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active);
  /** Если hasActiveSubscription undefined, значит redirectToInitialRoute внутри метода navigateAfterSignIn
   *  был вызван до получения ответа по запросу на currentTenantInfo.
   * Вернем reject, чтобы не отработала установка лоадера в false(переменная isGlobalEntitiesLoading)
   */
  if (hasActiveSubscription === undefined) {
    return Promise.reject();
  }

  const hasInstanceLicense = !!store.state.app.appSettings?.licenseSettings;
  const hasLicenseRole = !!store.state.tenants.currentTenantInfo?.licenseRole;

  // редиректим на корневой роут, если:
  // на инстансе есть настройки лицензии, но не засечена роль тенанта
  // или нет настроек лицензии (используется биллинг), но тенант не верифицирован или не имеет активной подписки

  if ((hasInstanceLicense && !hasLicenseRole)
     || (!hasInstanceLicense && (!isTenantVerified() || !hasActiveSubscription))) {
    return router.push('/');
  }

  // фильтруем страницы без типа withDivider, т.к. нам нужны только основные
  const firstPage = siderMenuItems.value
    .filter((item: TCommonSchemaItem) => item.type !== ECommonSchemaItemType.withDivider)[0]?.path || null;

  if (firstPage) {
    return router.push(firstPage);
  }
  return Promise.resolve();
};

export default redirectToInitialRoute;
