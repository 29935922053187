import {
  nextTick,
  Ref,
  ref,
  watch,
} from 'vue';

import { DropdownList } from '@/ui';
import { EKeyboardKey } from '@/constants';

import DatePickerInput from '../components/DatePickerInput/index.vue';

type TUseChangeDropdownVisibility = {
  dropdownRef: Ref<InstanceType<typeof DropdownList> | null>,
  datePickerInputRef: Ref<InstanceType<typeof DatePickerInput> | null>,
};

export const useChangeDropdownVisibility = ({
  dropdownRef,
  datePickerInputRef,
}: TUseChangeDropdownVisibility) => {
  const isDropdownVisible = ref(false);
  const isSelectFocus = ref(false);

  /**
   * Признак того, что событие blur у Select не должно вызывать логику закрытия панели dropdown.
   * Используется только при переходе с одного Select на другой (месяц <-> год).
   * Потребность в данном параметре обусловлена тем, что при переключении между элементами, событие blur элемента, который покидаем,
   * возникает позже, чем событие focus элемента, на который переключаемся. И если не обработать такую ситуацию, будут непреднамеренные закрытия dropdown.
   */
  const isPreviousSelectBlurPrevent = ref(false);

  const isDropdownFocused = () => dropdownRef.value?.dropdown?.isEqualNode(document.activeElement);

  const handleDropdownBlur = () => {
    closeDropdown();
  };

  const handleSelectFocus = () => {
    // Если флаг isSelectFocus true значит было переключение с Select (месяц) на Select (год) или наоборот (год <-> месяц).
    // Устанавливаем признак isPreviousSelectBlurPrevent, для корректной работы обработчика события blur предыдущего активного Select.
    if (isSelectFocus.value) {
      isPreviousSelectBlurPrevent.value = true;
    } else {
      isSelectFocus.value = true;
    }
  };

  const handleSelectBlur = () => {
    if (isPreviousSelectBlurPrevent.value) {
      isPreviousSelectBlurPrevent.value = false;

      return;
    }

    isSelectFocus.value = false;

    setTimeout(closeDropdown);
  };

  const resetDropdownVisibilityOptions = () => {
    isSelectFocus.value = false;
    isDropdownVisible.value = false;
    isPreviousSelectBlurPrevent.value = false;

    datePickerInputRef.value?.resetSelectedSection();
  };

  const closeDropdown = () => {
    /**
     * Если фокус на элементе, размещенном в контейнере dropdown (к примеру, фокус на кнопке "Сегодня").
     * Или если фокус на одном из элементов (панель dropdown, Select месяц/год)
     */
    if (dropdownRef.value?.dropdown?.contains(document.activeElement) || isDropdownFocused() || isSelectFocus.value) {
      return;
    }

    resetDropdownVisibilityOptions();
  };

  const handleEscapeDown = (event: KeyboardEvent) => {
    if (event.key === EKeyboardKey.escape) {
      resetDropdownVisibilityOptions();

      // отменяем событие, так как в Safari нажатие ESC приводит к выходы из полноэкранного режима браузера
      event.preventDefault();
    }
  };

  const handleControlWrapperFocus = () => {
    isDropdownVisible.value = true;

    setDropdownFocus();
  };

  const handleDropdownClose = () => {
    resetDropdownVisibilityOptions();
  };

  const setDropdownFocus = () => {
    nextTick(() => {
      dropdownRef.value?.focus({ preventScroll: true });
    });
  };

  watch(isDropdownVisible, () => {
    if (isDropdownVisible.value) {
      document.addEventListener('keydown', handleEscapeDown);
    } else {
      document.removeEventListener('keydown', handleEscapeDown);
    }
  });

  return {
    isDropdownVisible,

    handleDropdownBlur,
    handleSelectFocus,
    handleSelectBlur,
    handleControlWrapperFocus,
    handleDropdownClose,
    setDropdownFocus,
  };
};
